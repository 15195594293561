<template>
  <div className="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Sandbox BCA Payment
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-form v-on:submit.prevent="onSubmit">
          <b-form-group id="CustomerNumber"
                        label="Customer Number"
                        label-for="CustomerNumber">
            <b-form-input id="CustomerNumber"
                          type="text"
                          v-model.trim="$v.form.CustomerNumber.$model"
                          :state="chkState('CustomerNumber')"
                          aria-describedby="CustomerNumberFedback"
                          placeholder="0"/>
            <b-form-invalid-feedback id="CustomerNumberFedback">
              <span v-if="!this.$v.form.CustomerNumber.required"> Customer Number tidak boleh kosong </span>
              <span v-if="!this.$v.form.CustomerNumber.numeric"> Customer Number harus berupa angka valid </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="PaidAmount"
                        label="Paid Amount"
                        label-for="PaidAmount">
            <b-form-input id="PaidAmount"
                          type="text"
                          v-model.trim="$v.form.PaidAmount.$model"
                          :state="chkState('PaidAmount')"
                          aria-describedby="PaidAmountFedback"
                          placeholder="0" />
            <b-form-invalid-feedback id="PaidAmountFedback">
              <span v-if="!this.$v.form.PaidAmount.required"> Customer Number tidak boleh kosong </span>
              <span v-if="!this.$v.form.PaidAmount.numeric"> Customer Number harus berupa angka valid </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button variant="primary" type="submit">Submit</b-button>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import { validationMixin } from "vuelidate"
  import { required, numeric } from "vuelidate/lib/validators"

  export default {
    name: 'sandboxs',
    computed: {
      formStr() { return JSON.stringify(this.form, null, 4) },
      isValid() { return !this.$v.form.$anyError },
      isDirty() { return this.$v.form.$anyDirty },
      invCheck() { return 'You must accept before submitting' },
    },
    mixins: [validationMixin],
    validations: {
      form: {
        CustomerNumber: {
          required,
          numeric
        },
        PaidAmount: {
          required,
          numeric
        }
      }
    },
    data() {
      return {
        isLoading: false,
        baseUrl: process.env.VUE_APP_SECRET + 'api/v1/bca/va/payments',
        form: {
          CompanyCode: '10794',
          CustomerNumber: '',
          RequestID: '201507131507262221400000001975',
          ChannelType: '6014',
          CustomerName: 'Customer BCA Virtual Account',
          CurrencyCode: 'IDR',
          PaidAmount: '',
          TotalAmount: '',
          SubCompany: '00000',
          TransactionDate: '15/03/2019 23:07:40',
          Reference: '1234567890',
          DetailBills: [],
          FlagAdvice: 'N',
          Additionaldata: ''
        },
        errors: {
          message: [],
          status: [],
          code: '',
          headers: [],
          form: {
            CustomerNumber: [],
            PaidAmount: [],
          },
        },
      }
    },
    methods: {
      onSubmit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
            this.isLoading = true
            this.form.TotalAmount = this.form.PaidAmount
            this.$http.post(this.baseUrl, this.form).then(() => {
              this.isLoading = false
              this.$swal.fire(
                'Success!',
                'Success Send Sandbox BCA Payment',
                'success'
              )
            }).catch((error) => {
              if (error.response) {
                this.isLoading = false
                this.$swal.fire(
                  'Failed!',
                  'Failed Send Sandbox BCA Payment',
                  'error'
                )
              }
            })
        }
      },
      chkState(val) {
        const field = this.$v.form[val]
        return !field.$dirty || !field.$invalid
      },
      findFirstError(component = this) {
        if (component.state === false) {
          if (component.$refs.input) {
            component.$refs.input.focus()
            return true
          }
          if (component.$refs.check) {
            component.$refs.check.focus()
            return true
          }
        }
        let focused = false
        component.$children.some((child) => {
          focused = this.findFirstError(child)
          return focused
        })

        return focused
      },
    },
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
.skus {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

#minutes__BV_label_ {
  visibility: hidden;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />

